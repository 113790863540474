import React from "react";

const ParagraphTitre = ({ item: { id, title } }) => {
  return (
    <h2
      className="paragraph-titre mb-50"
      key={id}
      id={title
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()}>
      {title}
    </h2>
  );
};

export default ParagraphTitre;
