import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import Layout from "components/Layout";
import Page from "./page";
import { ApiClient } from "utils/apiClient";
// import NotFound from "utils/NotFound";

const WebPages = () => {
    const [items, setItems] = useState([]);
    const api = ApiClient();

    useEffect(() => {
        fetchMenus();
        // fetchMenu2();
        // fetchMenu3();
    }, []);

    const fetchMenus = async () => {
        let menus = [];

        await Promise.all([
            api.getMenu("main").then((response) => {
                menus = [...menus, ...response.data];
            }),
            api.getMenu("footer").then((response) => {
                menus = [...menus, ...response.data];
            }),
            api.getMenu("footer2").then((response) => {
                menus = [...menus, ...response.data];
            })
        ]);
        setItems((items) => [...items, ...menus]);
    };

    const fetchMenu2 = async () => {
        api.getMenu("footer").then((response) => {
            setItems((items) => [...items, ...response.data]);
        });
    };

    const fetchMenu3 = async () => {
        api.getMenu("footer2").then((response) => {
            setItems((items) => [...items, ...response.data]);
        });
    };

    return (
        items.length ?
            (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Redirect to="/accueil" />
                </Route>
                <Route
                    exact
                    path={["/:alias", "/node/:alias"]}
                    render={(props) => (
                        <>
                            <Layout {...props} items={items}>
                                <Page {...props} items={items} />
                            </Layout>
                        </>
                    )}
                />
                <Route
                    path="/:alias*"
                    render={(props) => (
                        <>
                            <Layout {...props} items={items}>
                                <Page {...props} items={items} />
                            </Layout>
                        </>
                    )}
                />
                {/*<Route component={NotFound} />*/}
            </Switch>
        </Router>)
            : null
    );
};

export default WebPages;
