import React from "react";
import Header from "./Header";
import SideMenu from "./SideMenu";
import styles from "styles/Layout.module.scss";
import Footer from "./Footer";
import PartenairesList from "./PartenairesList";
import ScrollArrow from "./ScrollArrow";

const Layout = ({children, items, ...props}) => {
    const path = props.location.pathname;

    return (
        <>
            <Header/>
            <main
                className={path === "/" || path === "/accueil" ? styles.Homepagewrapper : styles.Wrapper}>
                <SideMenu items={items} {...props} />
                <div className={styles.Main}>{children}</div>
            </main>
            {path != "/partenaires" ? <PartenairesList/> : <div style={{height:"100px"}}>&nbsp;</div>}
            <ScrollArrow/>
            <Footer/>
        </>

    );
};

export default Layout;
