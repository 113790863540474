import { ApiClient } from "utils/apiClient";
import React, { useState, useEffect } from "react";
import "styles/Partenaires.scss";

function PartenairesList() {
  const api = ApiClient();

  const [partenaires, setPartenaires] = useState([]);

  useEffect(() => {
    fetchPartenaires();
  }, []);

  const fetchPartenaires = () => {
    api.getContent("/api/partenaires").then((response) => {
      setPartenaires(response.data);
    });
  };

  return (
      <div className="partenaire-list">
        {partenaires.map((partenaires) => (
          <div key={partenaires.nid} className="partenaire-partenaires">
            <a
              href={partenaires.lien_partenaire}
              target="_blank"
              rel="noreferrer">
              <img
                className="img-partenaires"
                alt="logo"
                src={
                  process.env.REACT_APP_URL_BACK + partenaires?.logo_export?.media_image
                }
              />
            </a>
          </div>
        ))}
      </div>
  );
}

export default PartenairesList;
