import { components } from "utils/mappingParagraphs";
import React from "react";

export function MenuFinder(menuList, alias) {
  // Nom des components : MyComponent
  // Nom des variables : myVariable

  //find node in first level menu

  const mainmenu = menuList.filter(
    (mainmenu) =>
      mainmenu.uri === "node/" + alias || mainmenu.relative === "/" + alias
  );
  if (mainmenu.length > 0) return mainmenu;

  if (!alias) {
    const homePage = menuList.filter((menu) => menu.relative === "/accueil");
    if (homePage && homePage.length > 0) return homePage;
  }

  //find node in second level menu
  for (let index = 0; index < menuList.length; index++) {
    const secondMenu = menuList[index]?.below?.filter(
      (secondMenu) =>
        secondMenu.uri === "node/" + alias ||
        secondMenu.relative === "/" + alias
    );
    if (secondMenu && secondMenu.length > 0)
      return [secondMenu[0], menuList[index]];
  }

  //find node in third level menu
  for (let indexMenu = 0; indexMenu < menuList.length; indexMenu++) {
    for (
      let indexBelow = 0;
      indexBelow < menuList[indexMenu].below?.length;
      indexBelow++
    ) {
      const lastMenu = menuList[indexMenu]?.below[indexBelow].below?.filter(
        (lastMenu) =>
          lastMenu.uri === "node/" + alias || lastMenu.relative === "/" + alias
      );
      if (lastMenu && lastMenu.length > 0)
        return [lastMenu[0], menuList[indexMenu].below[indexBelow]];
    }
  }
}

export function routesFinder(menuList) {
  const routes = [];
  menuList.forEach(element => {
    // routes.push(element.relative);
    element?.below?.forEach(below => {
      routes.push(below.relative);
      below?.below?.forEach(below => {
        routes.push(below.relative);
      });
    });
  })
  return routes;
}


export function renderParagraph(item, index) {

  const component = item.bundle;
  const ParagraphType = components[component];
  // return paragraphType
  return React.createElement(ParagraphType, { item, key:index });
}