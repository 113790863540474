import React from "react";

const ParagraphPartenaire = ({
  item: { id, lien_partenaire, logo },
}) => {
  return (
    <div key={id} className="col-5 col-sm-3 col-md-3 mb-2 mt-2 justify-content-center align-items-center logo-partenaire">
      <a href={lien_partenaire} rel="noreferrer" target="_blank">
        <img alt="img" src={process.env.REACT_APP_URL_BACK + logo.media_image} />
      </a>
    </div>
  );
};

export default ParagraphPartenaire;
