import React from "react";

const ParagraphTexte = ({ item: { id, body } }) => {
  return (
    <div
      className="col-md-12 mb-1"
      key={id}
      dangerouslySetInnerHTML={{
        __html: body,
      }}
    />
  );
};

export default ParagraphTexte;
