import React from "react";
import SiteInformations from "./SiteInformations.jsx";
import {MenuBar} from "./MenuBar.jsx";
import styles from "styles/Header.module.scss";

const Header = () => {
  return (
    <header className={styles.headerBar}>
      <SiteInformations />
      <MenuBar />
    </header>
  );
};

export default Header;
