import { ApiClient } from "utils/apiClient";
import React, { useState, useEffect, useRef } from "react"; // Importez useRef ici

function Documents() {
  const api = ApiClient();

  const [documents, setDocuments] = useState([]);
  const isMounted = useRef(true); // Utilisez useRef

  useEffect(() => {
    isMounted.current = true;
    fetchItems();

    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchItems = async () => {
    try {
      const response = await api.getContent("/api/documents");
      if (isMounted.current) {
        setDocuments(response.data);
      }
    } catch (error) {
      console.error("Une erreur s'est produite lors de la récupération des documents : ", error);
    }
  };

  return (
      <div className="paragraph-documents mb-80">
        {documents.map((item, index) => (
            item.afficher_le_lien_sur_la_page_doc ? (
                <li key={index}>
                  <a
                      rel="noreferrer"
                      target="_blank"
                      href={process.env.REACT_APP_URL_BACK + item.field_media_file}
                  >
                    <div dangerouslySetInnerHTML={{ __html: item.name }} />
                  </a>
                </li>
            ) : null
        ))}
      </div>
  );
}

export default Documents;
