import React from "react";

const ParagraphFondGris = ({ item: { id, title, body } }) => {
  return (
    <div key={id} className="txt-fnd-gris mb-50">
      <h3
        className="col-lg-12"
        dangerouslySetInnerHTML={{
          __html: title,
        }}></h3>
      <div
        className="col-lg-12"
        dangerouslySetInnerHTML={{
          __html: body,
        }}></div>
    </div>
  );
};

export default ParagraphFondGris;
