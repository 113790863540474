import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";

const instance = createInstance({
  urlBase: "https://matomo.esea-na.fr",
  siteId: 43,
  trackerUrl: "https://matomo.esea-na.fr/matomo.php"
});

//import reportWebVitals from './reportWebVitals';
//  React fragment inutile
ReactDOM.render(
  <MatomoProvider value={instance}>
    <App />
  </MatomoProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
