import React, { useState, useEffect } from "react";
import "styles/MenuBar.scss";
// import { NavLink as Link } from "react-router-dom";
import logo from "assets/logo.svg";
import { Navbar, NavDropdown } from "react-bootstrap";
import { ApiClient } from "utils/apiClient";
import { AutoLink } from "components/AutoLink";

// component for navbar menu
export function MenuBar(props) {
  const api = ApiClient();

  const [items, setitems] = useState([]);

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    api.getMenu("main").then((response) => {
      setitems(response.data);
    });
  };

  // const NavLink = ({ path, children, ...other }) => {
  //   return (
  //       <Link {...other} to={path}>
  //         {children}
  //       </Link>
  //   );
  // };

  const NavMenu = ({ children }) => {
    return <div className="navMenu">{children}</div>;
  };

  return (
      <>
        <NavMenu>
          <Navbar bg="transparent" expand="xl" variant="dark">
            <div className="logolink">
              <AutoLink link="/">
                <img src={logo} className="logo" alt="logo" />
              </AutoLink>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <nav className="Nav mr-auto">
                {items.map((item) => {
                  if (item.below) {
                    return (
                        <NavDropdown
                            key={"drop" + item.key}
                            title={item.title}
                            id="basic-nav-dropdown"
                        >
                          {item.below.map((item) => (
                              <AutoLink
                                  key={item.key}
                                  className="navlink dropdown-item"
                                  link={
                                    item.relative !== "/accueil" ? item.relative : "/"
                                  }
                              >
                                {item.title}
                              </AutoLink>
                          ))}
                        </NavDropdown>
                    );
                  } else {
                    return (
                        <AutoLink
                            key={item.key}
                            link={item.relative !== "/accueil" ? item.relative : "/"}
                            className={
                              item.relative !== "/accueil" ? null : "homepagelink"
                            }
                        >
                          {item.title}
                        </AutoLink>
                    );
                  }
                })}
              </nav>
            </Navbar.Collapse>
          </Navbar>
        </NavMenu>
      </>
  );
}
