import "styles/Footer.scss";
import {ApiClient} from "../utils/apiClient";
import React, {useEffect, useState} from "react";
import {NavLink as Link} from "react-router-dom";
import { Navbar } from "react-bootstrap";
import logo_esea from "assets/logo_esea.png";

const Footer = (props) => {

    const api = ApiClient();

    const [items, setitems] = useState([]);

    useEffect(() => {
        fetchItems();
    }, [props.items]);

    const fetchItems = async () => {
        api.getMenu("footer2").then((response) => {
            setitems(response.data);
        });
    };

    const NavMenu = ({children}) => {
        return <div className="navMenuFooter">{children}</div>;
    };

    const NavLink = ({path, children, ...other}) => {
        return (
            <Link {...other} to={path}>
                {children}
            </Link>
        );
    };

    return <footer>
        <div className={"col-12 d-flex justify-content-between align-items-center"}>
        <NavMenu>
            <Navbar>
                <div className="FooterBar">
                    <nav className="FooterNav">
                            {items.map((item) => (
                                <NavLink key={item.key} path={item.relative}>
                                        {item.title}
                                </NavLink>
                            ))}
                    </nav>
                </div>
            </Navbar>
        </NavMenu>
            <div>
                Site réalisé et hébergé par&nbsp;&nbsp;:&nbsp;&nbsp;<a target="_blank" href={"https://www.esea-na.fr/"}><img src={logo_esea} className="logoEsea" alt="logo ESEA"/></a>
            </div>
        </div>
    </footer>
};

export default Footer;