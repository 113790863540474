import React, {useState, useEffect} from "react";
import "styles/Page.scss";
import {ApiClient} from "utils/apiClient";
import {MenuFinder, renderParagraph} from "utils/tools";
import Documents from "components/Documents";
import PartenairesList from "components/PartenairesList";
import {useMatomo} from '@datapunt/matomo-tracker-react'

//component for render page contents from Drupal Api
function Page({location: {pathname}, ...props}) {
    const api = ApiClient();

    const {trackPageView} = useMatomo()

    const MainContent = ({children, cssClass}) => {
        return <div className={"mainContent row " + cssClass}>{children}</div>;
    };

    const [item, setItem]                   = useState(null);
    const [contentLoaded, setContentLoaded] = useState(false);

    //change component content when alias change
    useEffect(() => {
        const menuElements = MenuFinder(props.items, pathname.substring(1));
        console.log(props.items, pathname.substring(1));
        // console.log(menuElements);
        if (menuElements && menuElements.length > 0) {
            // console.log('ici')
            const menu = menuElements[0];
            const tmp  = menu.uri.split("/");
            const node = tmp[tmp.length - 1];
            trackPageView();
            if (node) {
                fetchItem(node);
            }
            else {
                setItem(false);
            }
            setTimeout(function () {
                window.scrollTo(0, 0);
            }, 100);
        }
        else {
            // console.log('pas ici')
            setItem(false);
        }
    }, [props.items, pathname]);


    //Request Drupal Api with node id in parameter
    const fetchItem = async (nid) => {
        api.getContent("/page_content/" + nid).then((response) => {

            // if response.data is empty, setContentLoaded to false
            if (response?.data?.length === 0 || !response?.data?.length) {
                setItem(false);
            }
            else {
                setItem(response.data[0]);
                setContentLoaded(true);
            }
            // setItem(response.data[0]);
            // setContentLoaded(true);
        });

    };

        if (item) {
            return (
                <MainContent>
                    {item.paragraphe_export?.map((item, index) => {
                        return renderParagraph(item, index)
                    })}
                    {pathname === "/documents" ? <Documents/> : null}
                    {/*{pathname !== "/partenaires" ?  <PartenairesList /> : null}*/}
                </MainContent>
            );
        }
        else if (item === false) {
            return <MainContent>
                <div style={{textAlign: "center"}}>
                    <div>
                        Page non trouvée
                    </div>
                </div>
            </MainContent>;
        }
            return null;
}

export default Page;
