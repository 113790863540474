import React from "react";

const ParagraphImage = ({ item: { id, media_image } }) => {
  return (
    <div key={id} className="paragraph-images mb-50">
        <img
          alt="img"
          src={process.env.REACT_APP_URL_BACK + media_image?.media_image}
        />

    </div>
  );
};

export default ParagraphImage;
