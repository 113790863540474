import { ApiClient } from "utils/apiClient";
import React, { useState, useEffect } from "react";
import { AutoLink } from "components/AutoLink";
import styles from "styles/SideMenu.module.scss";
import { MenuFinder } from "utils/tools";
import { FaAngleRight } from "react-icons/fa";

//component for left sidebar menu
// A réintégrer doans ton component principal
function SideMenu(props) {
  const Nav = ({ children }) => {
    return <nav className={styles.Nav}>{children}</nav>;
  };

  const Navlist = ({ children }) => {
    return <ul className={styles.Navlist}>{children}</ul>;
  };

  const api = ApiClient();
  const [currentElement, setCurrentElement] = useState(null);

  const alias = props.match.params.alias;

  useEffect(() => {
    const elements = MenuFinder(props.items, alias);
    if (elements && elements[0]?.below) {
      setCurrentElement(elements && elements.length > 1 && elements[0]);
    } else {
      setCurrentElement(elements && elements.length > 1 && elements[1]);
    }
  }, [props.items, alias]);

  if (currentElement) {
    return (
      <div className={styles.SideBar}>
        <Nav>
          <Navlist>
            {currentElement.uri ? (
              <AutoLink link={currentElement.relative}>
                <div className={styles.navTitle}>{currentElement.title}</div>
              </AutoLink>
            ) : (
              <div className={styles.navTitle}>{currentElement.title}</div>
            )}
            {currentElement &&
              currentElement.below?.map((item) => (
                <AutoLink key={item.key} link={item.relative}>
                  <li>
                    <FaAngleRight />
                    &nbsp;
                    {item.title}
                  </li>
                </AutoLink>
              ))}
          </Navlist>
        </Nav>
      </div>
    );
  } else return null;
}

export default SideMenu;
