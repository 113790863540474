import axios from "axios";


export function ApiClient() {

  const getData = async (path) => {


    //get request on Drupal Api
    var config = {
      method: "get",
      url: process.env.REACT_APP_URL_BACK + path,
    };

    return axios(config);
  };

  //resquest for menus
  const getMenu = async (menu) => {
    return getData("/api/menu_items/" + menu);
  };

  const getContent = async (route, nid = "") => {
    return getData(route + nid);
  };


  return {
    getData,
    getMenu,
    getContent,
  };
}
