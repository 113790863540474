
import { ParagraphTexte, ParagraphTitreTexte, ParagraphTitre, ParagraphFondGris, ParagraphTexteGaucheImageDroite, ParagraphVignette, ParagraphImage, ParagraphDocument, ParagraphPartenaire } from "components/paragraphs";



export const components =

{
  titre_texte: ParagraphTitreTexte,
  titre: ParagraphTitre,
  texte: ParagraphTexte,
  texte_fond_gris: ParagraphFondGris,
  texte_gauche_image_droite: ParagraphTexteGaucheImageDroite,
  image: ParagraphImage,
  partenaire: ParagraphPartenaire,
  vignette: ParagraphVignette,
  documents: ParagraphDocument
}
