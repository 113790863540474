import React from "react";

const ParagraphTexteGaucheImageDroite = ({ item: { id, body, media_image } }) => {
  return (
    <div key={id} className="col-lg-12 txt-gch-img-drt mb-50">
      <div
        className="col-lg-6 txt-gch-img-drt-body"
        dangerouslySetInnerHTML={{
          __html: body,
        }}></div>
      <img
        className="col-lg-6 txt-gch-img-drt-img"
        alt="img"
        src={process.env.REACT_APP_URL_BACK + media_image.media_image}
      />
    </div>
  );
};

export default ParagraphTexteGaucheImageDroite;
