import React, { useState, useEffect } from "react";
import "styles/SiteInformations.scss";
import { NavLink as Link } from "react-router-dom";
import { ApiClient } from "utils/apiClient";
import { AutoLink } from "components/AutoLink";

// component for navbar menu
function SiteInformations() {
  const api = ApiClient();
  // Renommer la variable avec un nom plus pertinent
  const [menu2, setMenu2] = useState([]);

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    api.getMenu("footer").then((response) => {
      setMenu2(response.data);
    });
  };
  
  const NavLink = ({ path, children, ...other }) => {
    return (
      <Link {...other} to={path}>
        {children}
      </Link>
    );
  };

  return (
    <div className="SiteInformations">
      {menu2.map((item) => (
        <AutoLink
          key={item.key}
          link={item.relative !== "/accueil" ? item.relative : "/"}
        >
          {item.title}
        </AutoLink>
      ))}
      {/* <a href="https://rncv-resu-mq.isped.u-bordeaux.fr/" target="_blank" rel="noreferrer">Accès privé</a> */}
    </div>
  );
}

export default SiteInformations;
